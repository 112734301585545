<template>
  <div>
    <b-row>
      <b-col md="6">
        <chart-organizations-by-sector
          :loading="loading"
          :organizations="organizations"
        />
      </b-col>
      <b-col md="6">
        <chart-organizations-by-country
          :loading="loading"
          :organizations="organizations"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <b-button
          :disabled="updatingBenchmarks"
          @click="updateBenchmarks"
        >
          Actualizar benchmarks
          <b-spinner
            v-if="updatingBenchmarks"
            small
          />
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BSpinner,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import organizations from '../../services/organizations'
import benchmarks from '../../services/benchmarks'
import ChartOrganizationsBySector from './ChartOrganizationsBySector.vue'
import ChartOrganizationsByCountry from './ChartOrganizationsByCountry.vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BSpinner,
    ChartOrganizationsBySector,
    ChartOrganizationsByCountry,
  },
  data() {
    return {
      organizations: [],
      loading: true,
      updatingBenchmarks: false,
    }
  },
  async mounted() {
    try {
      this.organizations = await organizations.getAll()
      this.loading = false
    } catch (err) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Error al recuperar datos',
          text: 'Por favor, vuelva a intentarlo',
          icon: 'AlertCircleIcon',
          variant: 'danger',
        },
      })
    }
  },
  methods: {
    async updateBenchmarks() {
      this.updatingBenchmarks = true
      try {
        await benchmarks.updateBenchmarks()
        this.showNotification('Listo',
          'Se actualizaron los benchmarks exitosamente',
          'CheckIcon',
          'success')
      } catch (err) {
        this.showNotification('Error al actualizar benchmarks',
          'Por favor, vuelva a intentarlo',
          'AlertCircleIcon',
          'danger')
      } finally {
        this.updatingBenchmarks = false
      }
    },
    showNotification(sTitle, sText, sIcon, sVariant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: sTitle,
          text: sText,
          icon: sIcon,
          variant: sVariant,
        },
      })
    },
  },
}
</script>
