// Firebase
import firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/functions'

export default {
  async updateBenchmarks() {
    const functions = firebase.functions()
    const updateBenchmarks = functions.httpsCallable('updateBenchmarks')
    const result = await updateBenchmarks()
    return result
  },
  async getByNetwork(netId, indictrId) {
    const functions = firebase.functions()
    const getNetworkBenchmarks = functions.httpsCallable('getNetworkBenchmarks')
    const result = await getNetworkBenchmarks({
      networkId: netId,
      indicatorId: indictrId,
    })
    return result
  },
}
