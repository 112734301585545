<template>
  <div>
    <top-management v-if="isTopManagement" />
    <client-dashboard v-if="isClient" />
    <advisor-dashboard v-if="isAdvisor" />
    <network-client-dashboard v-if="isNetworkClient" />
  </div>
</template>

<script>
import TopManagement from '../components/home/TopManagementDashboard.vue'
import ClientDashboard from '../components/home/ClientDashboard.vue'
import AdvisorDashboard from '../components/home/AdvisorDashboard.vue'
import NetworkClientDashboard from '../components/home/NetworkClientDashboard.vue'

export default {
  components: {
    TopManagement,
    ClientDashboard,
    AdvisorDashboard,
    NetworkClientDashboard,
  },
  computed: {
    isTopManagement() {
      return (this.$store.state.services.userInfo) ? this.$store.state.services.userInfo.type === 'Gerencia' : false
    },
    isClient() {
      return (this.$store.state.services.userInfo) ? this.$store.state.services.userInfo.type === 'Cliente' : false
    },
    isAdvisor() {
      return (this.$store.state.services.userInfo) ? this.$store.state.services.userInfo.type === 'Asesor' : false
    },
    isNetworkClient() {
      return (this.$store.state.services.userInfo) ? this.$store.state.services.userInfo.type === 'ClienteRed' : false
    },
  },
}
</script>

<style>

</style>
