<template>
  <b-col
    lg="4"
  >
    <b-card>
      <b-media no-body>
        <b-media-aside>
          <b-img
            ref="previewEl"
            rounded
            :src="logoUrl"
            height="120"
          />
        </b-media-aside>
        <b-media-body>
          <h4>
            <b-link
              :to="{ name: 'organizations-view', params: { id: organizationId } }"
              class="font-weight-bold d-block"
            >
              {{ organizationDisplayName }}
            </b-link>
          </h4>

        </b-media-body>
      </b-media>
    </b-card>
  </b-col>
</template>
<script>
import {
  BCol, BCard, BLink, BMedia, BMediaAside, BMediaBody, BImg,
} from 'bootstrap-vue'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import organizations from '../../services/organizations'

export default {
  components: {
    BCol,
    BCard,
    BLink,
    BMedia,
    BMediaAside,
    BMediaBody,
    BImg,
  },
  props: {
    organizationId: {
      type: String,
      required: true,
    },
    organizationName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      logoUrl: '',
      organization: null,
    }
  },
  computed: {
    organizationLogoFilename() {
      return (this.organization) ? this.organization.logo : ''
    },
    organizationDisplayName() {
      return (this.organization) ? this.organization.name : this.organizationName
    },
  },
  async mounted() {
    await this.fetchOrganization()
  },
  methods: {
    async fetchOrganization() {
      try {
        this.organization = await organizations.get(this.organizationId)
        if (this.organization.logo) {
          await this.fetchLogo()
        }
      } catch (err) {
        this.showNotification('Error al obtener datos de la organización', err.message, 'AlertCircleIcon', 'danger')
      }
    },
    async fetchLogo() {
      try {
        this.logoUrl = await organizations.getLogoURL(this.organizationId, this.organizationLogoFilename)
      } catch (err) {
        this.showNotification('Error al obtener el logo', err.message, 'AlertCircleIcon', 'danger')
      }
    },
    showNotification(sTitle, sText, sIcon, sVariant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: sTitle,
          text: sText,
          icon: sIcon,
          variant: sVariant,
        },
      })
    },
  },
}
</script>
