<template>
  <b-row>
    <organization-card
      v-for="o in organizations"
      :key="o.organizationId"
      :organization-id="o.organizationId"
      :organization-name="o.name"
    />
  </b-row>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { BRow } from 'bootstrap-vue'
import organizations from '../../services/organizations'
import OrganizationCard from './OrganizationCard.vue'

export default {
  components: {
    OrganizationCard,
    BRow,
  },
  data() {
    return {
      organizations: [],
      loading: true,
    }
  },
  computed: {
    userInfo() {
      return this.$store.state.services.userInfo
    },
  },
  async mounted() {
    try {
      this.organizations = await organizations.getAllByNetworkId(this.userInfo.network.id)
      this.loading = false
    } catch (err) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Error al recuperar datos',
          text: 'Por favor, vuelva a intentarlo',
          icon: 'AlertCircleIcon',
          variant: 'danger',
        },
      })
    }
  },
}
</script>
