<template>
  <b-row>
    <organization-card
      v-for="oc in organizationContacts"
      :key="oc.id"
      :organization-id="oc.organization.id"
      :organization-name="oc.organization.name"
    />
  </b-row>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { BRow } from 'bootstrap-vue'
import organizationContacts from '../../services/organizationContacts'
import OrganizationCard from './OrganizationCard.vue'

export default {
  components: {
    OrganizationCard,
    BRow,
  },
  data() {
    return {
      organizationContacts: [],
      loading: true,
    }
  },
  computed: {
    userInfo() {
      return this.$store.state.services.userInfo
    },
  },
  async mounted() {
    try {
      this.organizationContacts = await organizationContacts.getAllByUserId(this.userInfo.uid)
      this.loading = false
    } catch (err) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Error al recuperar datos',
          text: 'Por favor, vuelva a intentarlo',
          icon: 'AlertCircleIcon',
          variant: 'danger',
        },
      })
    }
  },
}
</script>
