<template>
  <b-card title="Instituciones por Sector">
    <div
      v-if="loading"
      class="text-center mt-5 mb-5"
    >
      <b-spinner
        variant="primary"
        class="mt-5 mb-5 large-spinner"
      />
    </div>

    <div
      v-if="!loading"
      class="pie-text text-center"
    >
      <h2 class="font-weight-bolder">
        {{ total }}
      </h2>
      <span class="font-weight-bold">Total</span>
    </div>

    <!-- echart -->
    <app-echart-doughnut
      v-if="!loading"
      :series="series"
      :legend="null"
    />
  </b-card>
</template>

<script>
import { BCard, BSpinner } from 'bootstrap-vue'
import AppEchartDoughnut from '@core/components/charts/echart/AppEchartDoughnut.vue'

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index
}

export default {
  components: {
    BCard,
    AppEchartDoughnut,
    BSpinner,
  },
  props: {
    organizations: {
      type: Array,
      default() { return [] },
    },
    loading: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    total() {
      return this.organizations.length
    },
    myData() {
      return this.organizations.map(o => o.country.name).filter(onlyUnique).map(country => ({
        name: country,
        value: this.organizations.filter(i => i.country.name === country).length,
      }))
    },
    series() {
      return [{
        name: 'Instituciones',
        type: 'pie',
        radius: ['40%', '80%'],
        avoidLabelOverlap: false,
        label: {
          show: true,
        },
        labelLine: {
          show: true,
        },
        data: this.myData,
      }]
    },
  },
}
</script>

<style lang="scss">
.card-body{
  position: relative;
  .pie-text{
    width: 105px;
    position:absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 48%;
    bottom: 0;
  }
  }

.echarts {
  width: 100% !important;
}
</style>
